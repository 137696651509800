<template>
  <section class="mt-4">
    <b-tabs>
      <b-tab-item label="Таблица">
        <b-pagination
          :total="total"
          :current="currentPage"
          :simple="false"
          :per-page="filters.limit"
          :paginated="false"
          :pagination-simple="true"
          order="is-right"
          @change="pageChange">
        </b-pagination>
        <div class="table-container">
          <b-table
            :data="data"
            :bordered="true"
            :narrowed="true"
            :hoverable="true"
            :focusable="true"
            default-sort-direction="desc"
            default-sort="date">
            <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
              <router-link :to="{name: 'a-rest', params: {id: props.row.id}}">
                {{ props.row.id }}
              </router-link>
            </b-table-column>
            <b-table-column field="country" label="Страна" centered v-slot="props">
              {{ props.row.country }}
            </b-table-column>
            <b-table-column field="city" label="Город" centered v-slot="props">
              {{ props.row.city }}
            </b-table-column>
            <b-table-column field="street" label="Улица" centered v-slot="props">
              {{ props.row.street }}
            </b-table-column>
            <b-table-column field="house" label="Дом" centered v-slot="props">
              {{ props.row.house }}
            </b-table-column>
            <b-table-column field="building" label="Строение" centered v-slot="props">
              {{ props.row.building }}
            </b-table-column>
<!--             <b-table-column field="entry" label="Подъезд" centered v-slot="props">
              {{ props.row.entry }}
            </b-table-column>
            <b-table-column field="floor" label="Этаж" centered v-slot="props">
              {{ props.row.floor }}
            </b-table-column>
            <b-table-column field="apartments" label="Квартира" centered v-slot="props">
              {{ props.row.apartments }}
            </b-table-column> -->
<!--             <b-table-column field="orders" label="Заказы" centered v-slot="props">
              {{ props.row.orders }}
            </b-table-column> -->
            <b-table-column field="action" label="Действие" centered v-slot="props">
              <b-tooltip label="Редактирование">
                <router-link :to="{name: 'a-rest', params: {id: props.row.id }}" class="navbar-item">
                  <font-awesome-icon icon="eye" />
                </router-link>
<!--                 <router-link :to="{name: 'a-del', params: {id: props.row.id }}" class="navbar-item">
                  <font-awesome-icon icon="trash" />
                </router-link> -->
              </b-tooltip>
            </b-table-column>
          </b-table>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="data">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
  import { EventBus, limits } from '@/utils'
  import { getRestAddresses, getPageRestAddresses } from '@/api/rest_address'
  // import { ToastProgrammatic as Toast } from 'buefy'
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'
  export default {
    name: "AddressessTable",
      components: {
        VueJsonPretty
      },
    data() {
      const data = []
  
      return {
        data: data,
        limits: limits,
        currentPage: 1,
        total: 0,
        filters: {
          order: 'asc',
          limit: 25
        },
      }
    },
    computed: {
      //
    },
    mounted: function() {
      let $this = this
  
      getRestAddresses(this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          $this.total = data.total
  
          data.data.forEach((item) => {
            this.data.push(item)
          })
        })
        .catch(
          this.data = []
        )
  
      // EventBus.$on('error', (error) => {
      //   $this.pageChange()
      //   this.$toast.open({
      //     // message: `${this.$i18n.t('form.error')} ${error}`,
      //     message: 'error',
      //     type: 'is-danger',
      //     position: 'is-bottom'
      //   })
      // })
  
      // EventBus.$on('fail', (error) => {
      //   $this.pageChange()
      //   this.$toast.open({
      //     // message: `${this.$i18n.t('form.fail')} ${error}`,
      //     message: 'fail',
      //     type: 'is-danger',
      //     position: 'is-bottom'
      //   })
      // })
  
    },
    beforeDestroy: function(){
      EventBus.$off('error')
      EventBus.$off('fail')
    },
    methods: {
      pageChange(page){
        if(page){
          this.currentPage = page
        }
        getPageRestAddresses(this.currentPage, this.filters, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data;
            
            this.data = []
            this.total = data.total
            data.data.forEach((item) => {
              this.data.push(item)
            })
  
          })
          .catch(
            this.data = []
          )
      },
    }
  }
</script>
<style></style>